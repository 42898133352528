import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _asyncToGenerator from "@babel/runtime/helpers/esm/asyncToGenerator";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import "core-js/modules/es.array.reduce.js";
import { defineStore } from 'pinia';
import { differenceInDays } from 'date-fns';
import { defaultExtractor, useApiOptions } from '~/plugins/api';
import { treeRequestHandler } from '~/components/areas/areasHelpers';
function suggestRequestHandler(v) {
  return defaultExtractor(v).reduce((res, _ref) => {
    var {
      id,
      text
    } = _ref;
    res[id] = text;
    return res;
  }, {});
}
function useCacheStore(getData) {
  var lastUpdate = ref();
  var data = ref({});
  var getDataPromise = undefined;
  function setValue(v) {
    lastUpdate.value = Date.now();
    data.value = v;
  }

  // Раз в 3 дня жесткое обновление (с дожиданием), иначе в бекграунде
  function update() {
    return _update.apply(this, arguments);
  }
  function _update() {
    _update = _asyncToGenerator(function* () {
      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var _getDataPromise = getData({
        limit: 10000
      });
      if (force || !lastUpdate.value || differenceInDays(lastUpdate.value, Date.now()) > 3) {
        getDataPromise = _getDataPromise;
      }
      setValue(yield _getDataPromise);
    });
    return _update.apply(this, arguments);
  }
  function getById(id) {
    return data.value[id] || id;
  }
  function getCacheDataAsync() {
    return getDataPromise || data.value;
  }
  return {
    data,
    lastUpdate,
    getById,
    update,
    getCacheDataAsync
  };
}
export var useSceneGroupCacheStore = defineStore('scene_groups_cache', () => {
  return _objectSpread({}, useCacheStore(useApiOptions('scene_groups', undefined, suggestRequestHandler)));
});
export var useBrandOwnerCacheStore = defineStore('brand_owner_cache', () => {
  return _objectSpread({}, useCacheStore(useApiOptions('brand_owner', undefined, suggestRequestHandler)));
});
export var useAreasCacheStore = defineStore('areas_cache', () => {
  return _objectSpread({}, useCacheStore(useApiOptions('areas', 'mdTree', treeRequestHandler)));
});