import _asyncToGenerator from "@babel/runtime/helpers/esm/asyncToGenerator";
import { UiIconName, UiIconTemplate, UiLinkAsButton, UiStyles } from '@frontend/ui-kit';
import { useApi } from '~/plugins/api';
import useAppStore from '~/stores/appStore';
import { useReleaseUpdates } from '~/components/header/useDocsLinks';
import openLink from '~/assets/utils/openLink';
export default defineComponent({
  components: {
    UiLinkAsButton,
    UiIconTemplate
  },
  setup() {
    var auth = useApi().auth;
    var store = useAppStore();
    var {
      link
    } = useReleaseUpdates();
    function checkAuth() {
      return _checkAuth.apply(this, arguments);
    }
    function _checkAuth() {
      _checkAuth = _asyncToGenerator(function* () {
        try {
          store.setLoading(true);
          yield auth.me();
          openLink(link.value);
        } finally {
          store.setLoading(false);
        }
      });
      return _checkAuth.apply(this, arguments);
    }
    return {
      UiStyles,
      link,
      locale: 'global.whatsnew',
      UiIconName,
      checkAuth
    };
  }
});