import { UiAvatar, UiDropDown, UiIconName, UiIconTemplate, UiList, UiListItem, UiListMenuItem, UiTooltip } from '@frontend/ui-kit';
import useUserStore from '~/stores/userStore';
export default defineComponent({
  components: {
    UiListMenuItem,
    UiListItem,
    UiList,
    UiDropDown,
    UiAvatar,
    UiTooltip,
    UiIconTemplate
  },
  setup() {
    var {
      logout
    } = useUserStore();
    var menu = [{
      onClick: () => {},
      href: '/old/profile',
      locale: 'nav.profile'
    }, {
      onClick: logout,
      locale: 'nav.logout'
    }];
    return {
      menu,
      locale: 'nav.profile',
      UiIconName
    };
  }
});