import { UiBreadcrumbs, UiIconButton, UiStyles, UiIconName } from '@frontend/ui-kit';
import { storeToRefs } from 'pinia';
import PageTitle from '~/components/layout/PageTitle.vue';
import { UiTeleportTarget } from '~/components/ui-kit/UiTeleport';
import { UiTeleportNames } from '~/components/ui-kit/UiTeleport/UiTeleportNames';
import useAppStore from '~/stores/appStore';
export default defineComponent({
  components: {
    UiBreadcrumbs,
    PageTitle,
    UiTeleportTarget,
    UiIconButton
  },
  setup() {
    var {
      backLink,
      breadcrumbs,
      titleShadow,
      headerTeleportId,
      title: titleLocale,
      mobileHeaderBorder
    } = storeToRefs(useAppStore());
    var teleportLeftName = computed(() => "".concat(UiTeleportNames.Header.Left, "-").concat(headerTeleportId.value));
    var teleportRightName = computed(() => "".concat(UiTeleportNames.Header.Right, "-").concat(headerTeleportId.value));
    var teleportBottomName = computed(() => "".concat(UiTeleportNames.Header.Bottom, "-").concat(headerTeleportId.value));
    return {
      titleLocale,
      breadcrumbs,
      teleportLeftName,
      teleportRightName,
      teleportBottomName,
      titleShadow,
      UiStyles,
      UiIconName,
      backLink,
      mobileHeaderBorder
    };
  }
});