import { locale } from '~/plugins/i18n';
export function useReleaseUpdates() {
  var link = computed(() => {
    return "/docs/".concat(locale.value === 'ru' ? 'ru' : 'en', "/updates/");
  });
  return {
    link
  };
}
export function useHelp() {
  var link = computed(() => {
    return "/docs/".concat(locale.value, "/");
  });
  return {
    link
  };
}