import { defineStore } from 'pinia';
import { usePortalBranding } from '@frontend/ui-kit';
import { useApi } from '~/plugins/api';
import { PagesNames } from '~/stores/pagesStore';
var additional = {
  awaitSettings: null,
  awaitBranding: null
};
export var HideOSAFilter = {
  None: 'none',
  OutOfAssortmentMatrix: 'out_of_assortment_matrix'
};
export var MetricsService = {
  None: 'none',
  Google: 'google',
  Yandex: 'yandex'
};
var useSettingsStore = defineStore('portalSettings', () => {
  /** Branding */
  var {
    branding,
    clean
  } = usePortalBranding();
  var isBrandingInitiated = ref(false);
  var metricsService = ref(MetricsService.None);
  function setBranding(settings) {
    isBrandingInitiated.value = true;
    branding.logo = settings.logo_url;
    branding.logoFull = settings.logo_full_url;
    branding.slogan = settings.slogan;
    metricsService.value = settings.metrics_service || MetricsService.None;
  }
  function cleanBranding() {
    if (!isBrandingInitiated.value) {
      clean();
    }
  }
  function initBranding() {
    var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (additional.awaitBranding) return;
    cleanBranding();
    if (force || !isBrandingInitiated) {
      additional.awaitBranding = useApi().portal_settings.branding().then(setBranding);
    }
  }
  /** Branding */

  var showRoutes = ref(false);
  var enableAreas = ref(false);
  var showSfaTasks = ref(false);
  var showPlanogramm = ref(false);
  var sfaTasksCustomImport = ref(false);
  var appSearchPhoto = ref(false);
  var globalFacingCalcGroup = ref(false);
  var maxReportPeriod = ref(null);
  var widgets = ref([]);
  var hideOSAFilter = ref(HideOSAFilter.None);
  var showBrandOwnerBoxesOnly = ref(false);
  var stitching = ref(false);
  var sync = reactive({
    [PagesNames.Stores]: false,
    [PagesNames.Users]: false,
    [PagesNames.Products]: false,
    [PagesNames.AssortmentMatrices]: false
  });
  var hideSync = reactive({
    [PagesNames.Stores]: false,
    [PagesNames.Users]: false,
    [PagesNames.Products]: false,
    [PagesNames.AssortmentMatrices]: false
  });
  var correction = reactive({
    show: false,
    type: ''
  });
  function initSettings() {
    additional.awaitSettings = useApi().portal_settings.list();
    return additional.awaitSettings.then(settings => {
      var _settings$search_inde;
      setBranding(settings);
      showRoutes.value = Boolean(settings.turn_routes_on);
      showSfaTasks.value = Boolean(settings.enable_sfa_tasks);
      showPlanogramm.value = Boolean(settings.calc_planogramm);
      sfaTasksCustomImport.value = Boolean(settings.sfa_tasks_custom_import);
      enableAreas.value = Boolean(settings.is_areas_enabled);
      appSearchPhoto.value = Boolean((_settings$search_inde = settings.search_indexes) === null || _settings$search_inde === void 0 ? void 0 : _settings$search_inde.photo);
      globalFacingCalcGroup.value = Boolean(settings.global_facing_calc_group);
      maxReportPeriod.value = settings.max_report_period || null;
      widgets.value = settings.portal_interface_settings || [];
      hideOSAFilter.value = settings.hide_OSA_filter || HideOSAFilter.None;
      showBrandOwnerBoxesOnly.value = settings.show_brandowner_boxes_only || false;
      // sync
      var automaticSync = settings.automatic_sync || {};
      sync[PagesNames.Stores] = Boolean(automaticSync.stores);
      sync[PagesNames.Users] = Boolean(automaticSync.users);
      sync[PagesNames.Products] = Boolean(automaticSync.products);
      sync[PagesNames.AssortmentMatrices] = Boolean(automaticSync['planed assortment']);

      // correction
      correction.show = Boolean(settings.show_correction);
      correction.type = settings.correction_type_on_realogram || '';
      stitching.value = settings.algorithms_stitching || false;
    }).catch(initBranding);
  }
  return {
    branding,
    metricsService,
    showRoutes,
    enableAreas,
    showSfaTasks,
    showPlanogramm,
    appSearchPhoto,
    globalFacingCalcGroup,
    maxReportPeriod,
    widgets,
    sync,
    hideSync,
    hideOSAFilter,
    showBrandOwnerBoxesOnly,
    correction,
    isBrandingInitiated,
    stitching,
    sfaTasksCustomImport,
    initBranding,
    initSettings
  };
});
export default useSettingsStore;
export { additional };