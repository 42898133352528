import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { UiAvatar, UiIconName, UiIconTemplate, UiTooltip } from '@frontend/ui-kit';
import { useNuxt2Meta } from '@nuxt/bridge/dist/runtime';
import useUserStore from '~/stores/userStore';
export default defineComponent({
  components: {
    UiAvatar,
    UiTooltip,
    UiIconTemplate
  },
  setup() {
    var {
      user
    } = useUserStore();
    var intercomAppId = useRuntimeConfig().public.INTERCOM_APP_ID;

    // Number of unread messages
    var unreadCount = ref(0);
    function addOnUnreadCountChangeListener() {
      window.Intercom('onUnreadCountChange', newCount => {
        unreadCount.value = newCount;
      });
    }

    // Is the intercom window open or not
    var isOpen = ref(false);
    function addOpenHideListeners() {
      window.Intercom('onShow', () => {
        isOpen.value = true;
      });
      window.Intercom('onHide', () => {
        isOpen.value = false;
      });
    }
    useNuxt2Meta({
      script: [{
        hid: 'stripe',
        src: 'https://widget.intercom.io/widget/' + intercomAppId,
        defer: true,
        // Changed after script load
        callback: () => {
          addOnUnreadCountChangeListener();
          addOpenHideListeners();
        }
      }]
    });
    var DEFAULT_SETTINGS = {
      app_id: intercomAppId,
      Portal: location.origin,
      custom_launcher_selector: '#intercom_support',
      hide_default_launcher: true,
      horizontal_padding: 24
    };
    onMounted(() => {
      window.intercomSettings = DEFAULT_SETTINGS;
      if (user) {
        var {
          username,
          email,
          id,
          role,
          login
        } = user;
        window.intercomSettings = _objectSpread(_objectSpread({}, DEFAULT_SETTINGS), {}, {
          name: username,
          email,
          'User ID': id,
          Role: role,
          Login: login
        });
      }
    });
    return {
      isOpen,
      unreadCount,
      locale: 'nav.message',
      UiIconName
    };
  },
  head: {}
});