import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { intervalToDuration } from 'date-fns';
import { UiList, UiListMenuItem } from '@frontend/ui-kit';
import { useI18n } from '~/plugins/i18n';
import useRecentPagesStore from '~/stores/recentPagesStore';
export default defineComponent({
  components: {
    UiListMenuItem,
    UiList
  },
  setup() {
    var i18n = useI18n();
    var resentPagesStore = useRecentPagesStore();
    var recentPages = computed(() => resentPagesStore.items);
    function getLabel(name, route) {
      if (name) return i18n.t(name);
      if (route.includes('/release')) return i18n.t('global.whatsnew');
      if (route.includes('/docs/')) return i18n.t('global.support');
      return i18n.t(route);
    }
    var items = computed(() => recentPages.value.map(el => {
      var _el$breadcrumbs;
      var label = getLabel(el.name, el.route);
      var link = el.route;
      var breadcrumbs = '';
      if (((_el$breadcrumbs = el.breadcrumbs) === null || _el$breadcrumbs === void 0 ? void 0 : _el$breadcrumbs.length) > 1) {
        var formatedBreadcrumbs = [...el.breadcrumbs];
        formatedBreadcrumbs.pop();
        breadcrumbs = "".concat(i18n.t('global.main'), "/").concat(formatedBreadcrumbs.map(el => i18n.t(el.name)).join('/'));
      }
      var timeInterval = intervalToDuration({
        start: new Date(el.timestamp),
        end: new Date()
      });
      var timeTitle = '';
      if (timeInterval.years) {
        timeTitle = i18n.tc('global.time.declensions.year', timeInterval.years);
      } else if (timeInterval.months) {
        timeTitle = i18n.tc('global.time.declensions.month', timeInterval.months);
      } else if (timeInterval.weeks) {
        timeTitle = i18n.tc('global.time.declensions.week', timeInterval.weeks);
      } else if (timeInterval.days) {
        timeTitle = i18n.tc('global.time.declensions.day', timeInterval.days);
      } else if (timeInterval.hours) {
        timeTitle = i18n.tc('global.time.declensions.hour', timeInterval.hours);
      } else if (timeInterval.minutes) {
        timeTitle = i18n.tc('global.time.declensions.minute', timeInterval.minutes);
      }
      var timestamp = "".concat(timeTitle || i18n.t('global.time.less_than_a_minute'), " ").concat(i18n.t('global.time.ago'));
      var subLabel = breadcrumbs ? "".concat(timestamp, " <br> ").concat(breadcrumbs) : timestamp;
      return {
        label,
        href: link,
        to: link.includes('old') ? '' : link,
        subLabel,
        timestamp,
        breadcrumbs
      };
    }));
    return {
      items
    };
  }
});