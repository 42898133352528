import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineStore } from 'pinia';
import useRecentPagesStore from '~/stores/recentPagesStore';
function useLoaderMessage() {
  var loadingMessage = ref('');
  function setLoadingMessage(message) {
    loadingMessage.value = message;
  }
  function resetLoadingMessage() {
    loadingMessage.value = '';
  }
  return {
    loadingMessage,
    setLoadingMessage,
    resetLoadingMessage
  };
}
var useAppStore = defineStore('app', () => {
  var loading = ref(false);
  var fixedHeader = ref(false);
  var fullPage = ref({
    desktop: false,
    mobile: false
  });
  var title = ref('Ailet');
  var defaultPadding = ref(true);
  var backLink = ref('');
  var breadcrumbs = ref([]);
  var titleShadow = ref(false);
  var darkBackground = ref(false);
  var headerTeleportId = ref('');
  var mobileHeaderBorder = ref(false);
  function setLoading(value) {
    loading.value = value;
  }
  var recentPages = useRecentPagesStore();
  function setTitle(value) {
    title.value = value;
    recentPages.updateTitle(value);
  }
  function changeBreadcrumb(payload) {
    if (breadcrumbs.value[payload.pos]) {
      breadcrumbs.value[payload.pos] = payload.element;
      breadcrumbs.value = [...breadcrumbs.value];
    }
  }
  var {
    loadingMessage,
    setLoadingMessage,
    resetLoadingMessage
  } = useLoaderMessage();
  var isLoading = computed(() => Boolean(loading.value || loadingMessage.value));
  return {
    loadingMessage,
    setLoadingMessage,
    resetLoadingMessage,
    loading,
    isLoading,
    fixedHeader,
    fullPage,
    title,
    defaultPadding,
    backLink,
    breadcrumbs,
    titleShadow,
    darkBackground,
    headerTeleportId,
    setLoading,
    setTitle,
    changeBreadcrumb,
    mobileHeaderBorder
  };
});
export default useAppStore;