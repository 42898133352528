import { defineStore } from 'pinia';
var useRecentPagesStore = defineStore('recentPages', () => {
  var items = ref([]);
  function addItem(item) {
    var newItems = items.value.filter(el => el.route !== item.route);
    if (newItems.length >= 10) {
      newItems.length = 9;
    }
    newItems.unshift(item);
    items.value = newItems;
  }
  function updateTitle(title) {
    if (items.value[0]) {
      items.value[0].name = title;
    }
  }
  function clear() {
    items.value = [];
  }
  return {
    items,
    addItem,
    updateTitle,
    clear
  };
});
export default useRecentPagesStore;