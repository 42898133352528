import { getQuery } from '~/assets/utils/getQuery';

export function loginRouter(redirect?: string) {
    let path = '/login';
    if (redirect && !['/'].includes(redirect)) {
        let redirectPath = redirect;
        if (redirect.startsWith('/')) {
            redirectPath = redirect.replace('/', '');
        }
        if (!['login', 'logout', 'logout/'].includes(redirectPath)) {
            path += getQuery({ source_uri: redirectPath });
        }
    }

    return path;
}
