import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { UiDropDown, UiLink, UiTooltip, UiCopyContainer, UiIconButton, UiIconName } from '@frontend/ui-kit';
import { useClipboard } from '@vueuse/core';
import { AR, useAccess } from '~/assets/mixins/accessMixin';
import { useApi } from '~/plugins/api';
import { ServiceName } from '~/components/services';
import useNotificationsStore from '~/stores/notificationsStore';
import { usePackageJson } from '~/assets/utils/usePackageJson';
import useUserStore from '~/stores/userStore';
export default defineComponent({
  components: {
    UiCopyContainer,
    UiDropDown,
    UiLink,
    UiTooltip,
    UiIconButton
  },
  setup() {
    var runtimeConfig = useRuntimeConfig().public;
    var packageJson = usePackageJson();
    var spaVersion = {
      name: ServiceName.Spa,
      version: usePackageJson().version
    };
    var uiKitVersion = {
      name: ServiceName.Uikit,
      url: '/ui-kit.md',
      version: packageJson.dependencies['@frontend/ui-kit'].replace('^', ''),
      changelog: true
    };
    var api = useApi();
    var apisInfo = ref([spaVersion, uiKitVersion]);
    var {
      canByRole
    } = useAccess();
    var {
      loggedIn
    } = useUserStore();
    function getUrl(name) {
      switch (name) {
        case ServiceName.Iris:
          return runtimeConfig.API_URL + '/docs';
        case ServiceName.Muse:
          return runtimeConfig.API_URL + '/md/docs';
        case ServiceName.Clio:
          return canByRole([AR.Admin, AR.Support]) ? runtimeConfig.CLIO_URL + '/docs' : '';
        default:
          return '';
      }
    }
    function hasChangelog(name) {
      switch (name) {
        case ServiceName.Iris:
          return false;
        case ServiceName.Muse:
          return true;
        case ServiceName.Clio:
          return true;
        default:
          return false;
      }
    }
    onActivated(loadVersions);
    loadVersions();
    function loadVersions() {
      if (!loggedIn) return;
      api.versions().then(answer => {
        if (!answer || !Array.isArray(answer)) return;
        apisInfo.value = [spaVersion, uiKitVersion, ...answer.map(_ref => {
          var {
            service_name,
            version,
            error
          } = _ref;
          return {
            name: service_name,
            url: getUrl(service_name),
            changelog: hasChangelog(service_name),
            version,
            error
          };
        })];
      });
    }
    var copyText = computed(() => {
      return apisInfo.value.filter(info => !info.error).map(info => "".concat(info.name, ": ").concat(versionToString(info.version))).join('\n');
    });
    var {
      addSuccess
    } = useNotificationsStore();
    var {
      copy
    } = useClipboard();
    function copyVersion(version) {
      copy(versionToString(version));
      addSuccess('Version copied');
    }
    function versionToString(v) {
      return "v".concat(v);
    }
    function hasChangelogLink(info) {
      if ([ServiceName.Clio, ServiceName.Muse].includes(info.name)) {
        return info.changelog && canByRole([AR.Admin, AR.Support, AR.Auditor, AR.DataAdmin, AR.SeniorEditor, AR.Supervisor]);
      }
      return !!info.changelog;
    }
    return {
      copyText,
      loggedIn,
      apisInfo,
      copyVersion,
      UiIconName,
      versionToString,
      hasChangelogLink
    };
  }
});