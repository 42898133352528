import "core-js/modules/es.array.sort.js";
import { UiAvatar, UiDropDown, UiList, UiListItem, UiTooltip } from '@frontend/ui-kit';
import { useI18n } from '~/plugins/i18n';
export default defineComponent({
  name: 'LocaleSelector',
  components: {
    UiList,
    UiListItem,
    UiAvatar,
    UiTooltip,
    UiDropDown
  },
  setup() {
    var i18n = useI18n();
    var locales = computed(() => i18n.locales);
    var activeLocale = computed(() => {
      var locale = locales.value.find(i => i.code === i18n.locale);
      localStorage.setItem('userLocale', locale === null || locale === void 0 ? void 0 : locale.code);
      return locale;
    });
    function setLocale(code, close) {
      i18n.setLocale(code);
      close();
    }
    var listOfLocales = computed(() => locales.value.filter(_ref => {
      var {
        code
      } = _ref;
      return code !== activeLocale.value.code && code !== 'dev';
    }).sort((a, b) => a.code.localeCompare(b.code)));
    return {
      listOfLocales,
      activeLocale,
      setLocale,
      locale: 'nav.language'
    };
  }
});