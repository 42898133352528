import { UiModalConfirmModule } from '@frontend/ui-kit';
import Loading from '~/components/layout/Loading.vue';
import { useI18n } from '~/plugins/i18n';
import LayoutPageHeader from '~/components/layout/LayoutPageHeader.vue';
import LayoutHeader from '~/components/header/LayoutHeader.vue';
import Notifications from '~/components/notifications/Notifications.vue';
import useStatsStore from '../stores/statsStore';
import useAppStore from '../stores/appStore';
export default {
  name: 'DefaultLayout',
  components: {
    LayoutHeader,
    Loading,
    LayoutPageHeader,
    Notifications,
    UiModalConfirmModule
  },
  middleware: 'recent-pages',
  setup() {
    useStatsStore();
    var route = useRoute();
    var appStore = useAppStore();
    var key = computed(() => {
      // if (route.name === 'visits-id') return 'visits-id';
      return route.fullPath;
      /**
       * Кейсы для проверки:
       * 1. Создать sfa задачу (перебросит на новую страницу), снова создать, должна открыться новая задача
       * 2. При смене активных фоток в визите не должна происходить перезагрузка страницы
       */
    });

    var {
      fixedHeader,
      fullPage,
      defaultPadding,
      darkBackground
    } = toRefs(appStore);
    var i18n = useI18n();
    var title = computed(() => i18n.t(appStore.title));
    return {
      key,
      fixedHeader,
      fullPage,
      title,
      defaultPadding,
      darkBackground
    };
  },
  head() {
    return {
      title: this.title
    };
  }
};