import { UiIconName, UiIconButton, toArray } from '@frontend/ui-kit';
import useNotificationsStore from '~/stores/notificationsStore';
export default defineComponent({
  name: 'NotificationsToasts',
  components: {
    UiIconButton
  },
  setup() {
    var notificationsStore = useNotificationsStore();
    var {
      notifications
    } = toRefs(notificationsStore);
    function closeNotification(id) {
      notificationsStore.deleteNotification(id);
    }
    return {
      toArray,
      UiIconName,
      notifications,
      closeNotification
    };
  }
});