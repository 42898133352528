import _asyncToGenerator from "@babel/runtime/helpers/esm/asyncToGenerator";
import { UiAvatar, UiIconName, UiIconTemplate, UiLink, UiTooltip } from '@frontend/ui-kit';
import { useApi } from '~/plugins/api';
import useAppStore from '~/stores/appStore';
import { useHelp } from '~/components/header/useDocsLinks';
import openLink from '~/assets/utils/openLink';
export default defineComponent({
  components: {
    UiLink,
    UiAvatar,
    UiTooltip,
    UiIconTemplate
  },
  setup() {
    var auth = useApi().auth;
    var store = useAppStore();
    var {
      link
    } = useHelp();
    function checkAuth() {
      return _checkAuth.apply(this, arguments);
    }
    function _checkAuth() {
      _checkAuth = _asyncToGenerator(function* () {
        try {
          store.setLoading(true);
          yield auth.me();
          openLink(link.value);
        } finally {
          store.setLoading(false);
        }
      });
      return _checkAuth.apply(this, arguments);
    }
    return {
      link,
      locale: 'global.support',
      UiIconName,
      checkAuth
    };
  }
});