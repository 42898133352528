import { defineStore } from 'pinia';
export var NotificationTypes;
(function (NotificationTypes) {
  NotificationTypes["Success"] = "success";
  NotificationTypes["Error"] = "error";
})(NotificationTypes || (NotificationTypes = {}));
function addId(notification) {
  if (!notification.id) {
    notification.id = new Date().toISOString();
  }
}
var useNotificationsStore = defineStore('notifications', () => {
  var notifications = ref([]);
  function pushNotification(notification) {
    if (notification.message) {
      addId(notification);
      notifications.value.unshift(notification);
      if (notification.closeAfter > 0) {
        setTimeout(() => deleteNotification(notification.id), notification.closeAfter);
      }
    }
  }
  function deleteNotification(id) {
    var index = notifications.value.findIndex(el => el.id === id);
    if (index > -1) {
      notifications.value.splice(index, 1);
    }
  }
  function addSuccess(message) {
    var {
      tc = false,
      closeAfter = 3000
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    pushNotification({
      type: NotificationTypes.Success,
      tc,
      message,
      closeAfter
    });
  }
  function addError(message) {
    var {
      tc = false,
      closeAfter = 5000
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    pushNotification({
      type: NotificationTypes.Error,
      tc,
      message,
      closeAfter
    });
  }
  return {
    notifications,
    deleteNotification,
    addSuccess,
    addError
  };
}, {
  // @ts-ignore
  persistedState: {
    includePaths: []
  }
});
export default useNotificationsStore;